<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-5 mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $t("modals.pollster.userPolls.title")
            }}</span>
            <span class="text-muted fw-bold fs-7">{{
              $t("modals.pollster.userPolls.desc")
            }}</span>
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-5">
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div
              v-if="isLoading"
              style="
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="text-center px-5 pb-7">
                <img
                  alt="Logo"
                  class="max-h-25px"
                  src="/media/loading_icon.gif"
                  style="width: 35px"
                />
              </div>
            </div>
            <div class="table-responsive" v-else-if="pollList.length > 0">
              <table
                class="table align-middle table-row-dashed fs-6 gy-5 no-footer"
                id="kt_customers_table"
                role="grid"
              >
                <!--begin::Table-->
                <thead>
                  <tr
                    class="
                      text-start text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                    role="row"
                  >
                    <td width="24%">
                      {{ $t("modals.pollster.userPolls.poll") }}
                    </td>
                    <td width="22%">
                      {{ $t("modals.pollster.userPolls.pollType") }}
                    </td>
                    <td width="12%">
                      {{ $t("modals.pollster.userPolls.duration") }}
                    </td>
                    <td width="18%">
                      {{ $t("modals.pollster.userPolls.status") }}
                    </td>
                    <td width="12%">
                      {{ $t("modals.pollster.userPolls.endDate") }}
                    </td>
                    <td width="12%">
                      {{ $t("modals.pollster.userPolls.detail") }}
                    </td>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600 text-center">
                  <tr
                    class="odd"
                    v-for="(poll, index) in pollList"
                    :key="index"
                  >
                    <td>{{ poll.poll.title }}</td>

                    <td>
                      <!-- Devam ediyor -->
                      <span
                        v-if="poll.poll.surveyIsPrivate !== true"
                        class="badge badge-light-success"
                        >{{ $t("common.general") }}</span
                      >
                      <!-- Sonlandırıldı -->
                      <span v-else class="badge badge-light-danger">{{ $t("common.special") }}</span>
                    </td>

                    <td>{{ poll.poll.sessionDuration }}</td>
                    <td>
                      <span class="badge badge-light-primary"
                        >{{ $t("common.completed") }}</span
                      >
                    </td>
                    <td>
                    {{ new Date(poll.poll.endDate).toLocaleDateString() }} 
                    </td>
                    <td>
                      <router-link
                        :to="'/pollster/userPoll/' + poll.poll.id + '/detail'"
                        class="
                          btn
                          btn-icon
                          btn-bg-primary
                          btn-active-color-light
                          btn-color-white
                          btn-sm
                          me-1
                        "
                      >
                        <span class="svg-icon svg-icon-3">
                          <inline-svg
                            src="/media/icons/duotune/art/art002.svg"
                          />
                        </span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table-->
              </table>
            </div>
            <div v-else class="card-px text-center">
              <div class="text-center px-5 pb-7"></div>
              <!--begin::Description-->
              <p class="text-gray-700 fs-6 fw-bold">
                <span>{{ $t("modals.pollster.zeroCount") }}</span>
              </p>
              <!--end::Description-->
            </div>
          </div>
                    <!--begin::Pagination-->
                    <div
            class="d-flex flex-stack flex-wrap pt-10"
            v-if="pollList.length != 0"
          >
            <!--begin::Controls-->
            <div class="d-flex flex-wrap my-1">
              <!--begin::Select wrapper-->
              <div class="m-0">
                <!--begin::Select-->
                <select
                  name="status"
                  data-control="select2"
                  data-hide-search="true"
                  class="
                    form-select form-select-white form-select-sm
                    fw-bolder
                    w-125px
                  "
                  v-model="pageSize"
                  @change="getPollList(1)"
                >
                  <option value="5">5</option>
                  <option value="10" selected>10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span>{{ $t("common.table.pagination.desc2") }}</span>
                <!-- Sayfa başı öğe sayısı -->

                <!--end::Select-->
              </div>
              <!--end::Select wrapper-->
            </div>
            <!--end::Controls-->

            <div class="fs-6 fw-bold text-gray-700">
              {{ totalItems }} {{ $t("common.table.pagination.desc") }}
              <!-- sonuç arasından -->
              {{ (currentPage - 1) * pageSize + 1 }} -
              {{ Math.min(currentPage * pageSize, totalItems) }}
              {{ $t("common.table.pagination.desc1") }}
              <!-- sonuç gösteriliyor. -->
            </div>

            <!--begin::Pages-->
            <ul class="pagination">
              <li
                class="page-item previous"
                @click="getPollList(currentPage - 1)"
                :class="{ disabled: currentPage === 1 }"
              >
                <a href="#" class="page-link"><i class="previous"></i></a>
              </li>

              <li
                v-for="page in pageCount"
                :key="page"
                class="page-item"
                :class="{ active: currentPage === page }"
              >
                <a href="#" class="page-link" @click="getPollList(page)">{{
                  page
                }}</a>
              </li>

              <li
                class="page-item next"
                @click="getPollList(currentPage + 1)"
                :class="{ disabled: currentPage === pageCount }"
              >
                <a href="#" class="page-link"><i class="next"></i></a>
              </li>
            </ul>
            <!--end::Pages-->
          </div>
          <!--end::Pagination-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
  <div
    class="modal fade"
    id="change_date_modal"
    ref="changeDateRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            id="vaccinate_modal_form"
            :validation-schema="form"
            class="form"
            @submit.prevent="updatePollDate"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">{{ $t("modals.pollster.updateEndDate") }}</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fs-7">
                {{ $t("modals.pollster.updateEndDateDesc") }}
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <div class="row mb-2">
              <div class="col-md-12">
                <div class="fv-row mb-10">
                  <label class="fs-6 fw-bold mb-2">{{
                    $t("modals.pollster.endDate")
                  }}</label>

                  <!--begin::Input-->
                  <div class="position-relative align-items-center">
                    <!--begin::Datepicker-->
                    <Field
                      v-model="newEndDate"
                      type="date"
                      name="form"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('modals.pollster.enterEndDate')"
                      :min="new Date().toISOString().split('T')[0]"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="Date" />
                      </div>
                    </div>
                    <!--end::Datepicker-->
                  </div>
                  <!--end::Input-->
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                  @click="updatePollDate()"
                >
                  <span class="indicator-label">
                    {{ $t("common.button.save") }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>
            </div>
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
</template>

<style>
body.swal2-height-auto {
  height: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import * as Yup from "yup";
import { StatisticPollListModel } from "@/domain/pollster/poll-statistic/poll-list/model/PollListModel";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const pollList = ref<StatisticPollListModel[]>([]);
    const originalPollList = ref<StatisticPollListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref<Date>();
    const selectedPollId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getPollList = async (page) => {
      isChanged.value = false;
      pollList.value = [];
      originalPollList.value = [];

      isLoading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const pollListModel: StatisticPollListModel = {
        poll: {},
        page: page,
        pageSize: pageSize.value,
      };

      pollsterController
        .statisticPollList(pollListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((poll) => {
              totalItems.value = poll.pagination.total;
              currentPage.value = poll.pagination.page;
              pageCount.value = poll.pagination.pageCount;
              pollList.value.push(poll);
              originalPollList.value.push(poll);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getPollList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollList(1);
    });

    return {
      url,
      pollList,
      form,
      newEndDate,
      changeDateRef,
      isLoading,
      getPollList,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
    };
  },
});
</script>
